<template>
    <input-dropdown
        variant="light"
        class="btn-min-width"
        :items="items"
        :value="value"
        v-bind="$attrs"
        @input="$emit('input', $event)"
    />
</template>

<script>
import InputDropdown from '@/components/common/InputDropdown';
import Role from '@/library/enumerations/Role';
export default {
    name: 'RoleDropdown',
    components: {InputDropdown},
    props: {
        value: {
            type: Number,
            required: false,
            default: undefined,
        },
    },
    data: () => ({
        items: Role.objects.map(role => ({label: role.translation, value: role.value})),
    }),
};
</script>
