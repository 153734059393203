<template>
    <b-modal
        :id="id"
        v-bind="$attrs"
        centered
        :title="title"
    >
        <b-form @submit.prevent="submit">
            <input-emails-list
                :errors="errors"
                @input="onEmails"
            />

            <div
                v-if="!hideOrganisationSelect && !hideRoleSelect"
                class="py-3 divider"
            >
                <hr>
            </div>

            <b-form-group
                v-if="!hideOrganisationSelect"
                label="Organisation"
            >
                <dropdown-collection
                    v-model="invitation.model"
                    :collection="organisations"
                    :unselected-text="$t('users.add.fields.organisation.unselected')"
                    allow-deselect
                    label="name"
                />
            </b-form-group>

            <b-form-group
                v-if="!hideRoleSelect"
                label="Role"
                :invalid-feedback="invitation.getErrors('roles.0')"
                :state="!invitation.getErrors('roles.0')"
            >
                <role-dropdown
                    v-model="invitation.roles[0]"
                    class="w-100"
                />
            </b-form-group>
        </b-form>

        <template v-if="invitation.fatal">
            <div class="py-3 divider">
                <hr>
            </div>

            <div class="text-error">
                {{ invitation.errors.message }}
            </div>
        </template>

        <template #modal-footer="{cancel}">
            <b-btn
                class="flex-grow-1"
                variant="light"
                @click="cancel"
            >
                {{ $t('buttons.cancel') }}
            </b-btn>

            <btn-resource
                class="flex-grow-1"
                variant="primary"
                :disabled="!submitEnabled"
                :resource="invitation"
                @click="submit"
            >
                {{ title }}
            </btn-resource>
        </template>
    </b-modal>
</template>

<script>
import DropdownCollection from '@/components/common/DropdownCollection';
import RoleDropdown from '@/components/users/RoleDropdown';
import Role from '@/library/enumerations/Role';
import {detainFeedback} from '@/library/helpers';
import InputEmailsList from '@/components/common/form/InputEmailsList';
import {Invitation} from '@/models/Invitation';
import {Group, Groups} from '@/models/vue-mc/Group';
import {uniq} from 'lodash';
import Model from '@/models/vue-mc/Model';

export default {
    name: 'UsersModalInviteUsers',
    components: {DropdownCollection, InputEmailsList, RoleDropdown},
    props: {
        id: {
            type: String,
            required: false,
            default: undefined,
        },
        role: {
            type: Number,
            required: false,
            default: undefined,
        },
        organisation: {
            type: Model,
            required: false,
            default: undefined,
        },
        hideRoleSelect: {
            type: Boolean,
            default: false,
        },
        hideOrganisationSelect: {
            type: Boolean,
            default: false,
        },
    },
    data: function() {
        return {
            input: '',
            invitation: this.createInitialInvitation(),
            organisations: new Groups(),
        };
    },
    computed: {
        errors() {
            return Object.entries(this.invitation._errors)
                .filter(([key]) => key.startsWith('emails.'))
                .reduce((errors, [key, value]) => {
                    errors[key.split('.')[1]] = value[0];

                    return errors;
                }, {});
        },
        title() {
            const role = typeof this.role === 'number' ? this.role : 'USER';

            const translatedRole = Role.$t(role, 'plural').toLowerCase();

            return this.$t('users.add.title', {type: translatedRole});
        },
        submitEnabled() {
            return this.invitation.emails.length >= 1;
        },
    },
    mounted() {
        if (this.$route.query.invite) {
            this.$bvModal.show(this.id);
        }
    },
    methods: {
        createInitialInvitation() {
            return new Invitation({
                roles: typeof this.role === 'number' ? [this.role] : [],
                model: this.organisation,
            });
        },
        onEmails(emails) {
            this.invitation.emails = emails;

            this.invitation._errors = {};
        },
        async submit() {
            await this.invitation.save();

            this.invitation = this.createInitialInvitation();

            this.$emit('invitations-created');

            detainFeedback(() => {
                this.$bvModal.hide(this.id);
            });
        },
    },
};
</script>
