import {applyAttributesMixin} from '@/models/vue-mc/helpers';
import Vue from 'vue';
import Role from '@/library/enumerations/Role';
import i18n from 'i18next';
import Model from './vue-mc/Model';
import Collection from './vue-mc/Collection';
import {Invitation} from '@/models/Invitation';
import {GroupUser} from '@/models/vue-mc/GroupUser';
import {User} from '@/models/User';

export class Member extends Model {
    get status() {
        const isInvitation = this.type === 'invitations';

        return {
            text: i18n.t(`common.words.${isInvitation ? 'pending' : 'active'}`),
            color: isInvitation ? 'secondary' : 'primary',
        };
    };

    /**
     * Translate and concatenate roles into a string.
     */
    get rolesAsString() {
        return Role.rolesAsString(this.roles);
    }

    defaults() {
        return {
            id: null,
        };
    }

    options() {
        return {
            endpoint: 'members',
        };
    }

    /**
     * Override `Model.getLocation` to use `type` instead of `endpoint` as the
     * location name prefix. This will result in:
     * - `clients/{id}`
     * - `invitations/{id}`
     * - `members` (Index route will still use `endpoint`)
     *
     * @param {string} name
     * @returns {Location} vue-router Location.
     */
    getLocation(name = 'edit') {
        let location = super.getLocation(name);

        if (name !== 'index') {
            const prefix = (function(type) {
                switch (type) {
                    case 'group-users': return 'clients';
                    case 'invitations': return 'users';
                    default: return type;
                }
            })(this.type);

            location.name = `${prefix}.${name}`;
        }

        return location;
    }

    toTypeModel() {
        const data = {
            id: this.id,
        };

        switch (this.type) {
            case 'group-users':
                return new GroupUser(data);

            case 'invitations':
                return new Invitation(data);

            case 'users':
                return new User(data);
        }
    }
}

export const Members = applyAttributesMixin(class Members extends Collection {

    get query() {
        const query = super.query;

        if (Role.includes(this.role)) {
            query.role = this.role;
        }

        return query;
    }

    defaults() {
        return {
            ...super.defaults(),
            role: undefined,
        };
    }

    options() {
        return {
            model: Member,
        };
    }

    /**
     * @return {Boolean} true if this collection has the model in its registry.
     */
    hasModelInRegistry(model) {
        return this.models
            .some(m => {
                return m.type === model.type
                    && m.isSame(model);
            });
    }
});
