<template>
    <div class="input-layout d-flex">
        <slot />

        <span class="icon">
            <slot name="icon" />
        </span>
    </div>
</template>

<script>
/**
 * Rounded box around a text input with a slot for an icon.
 */
export default {
    name: 'InputLayout',
};
</script>

<style lang="scss">
.input-layout {
    $padding-right: 1.923rem;
    position: relative;
    padding: $btn-padding-y $padding-right $btn-padding-y $btn-padding-x;
    background: $gray-100;
    border-radius: $border-radius;

    .form-control {
        height: $btn-font-size * $btn-line-height;
        padding: 0;
        font-size: $btn-font-size;
        line-height: $btn-line-height;
        background: transparent;
        border: none;
    }

    .icon {
        position: absolute;
        right: 0;
        padding-left: .35rem;
        transform: translateX(-100%);
    }

    > * {
        align-self: center;
    }
}
</style>
