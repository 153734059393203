<template>
    <div class="model-select">
        <input-layout class="search">
            <input-debounced
                ref="input"
                v-model="collection.searchQuery"
                :placeholder="placeholder"
                :tag="BFormInput"
                autocomplete="off"
                @focusin="loadInitial"
            />

            <b-form-input
                v-if="typeof unselectedText === 'string' || value !== undefined"
                :value="value !== undefined ? getLabel(value) : unselectedText"
                class="selected-overlay"
            />

            <template #icon>
                <fa
                    v-if="collection.loading"
                    icon="spinner"
                    size="xs"
                    spin
                />

                <fa
                    v-else
                    size="xs"
                    class="caret"
                    icon="caret-down"
                />
            </template>
        </input-layout>

        <div
            v-infinite-scroll="() => loadMore()"
            class="items"
            :class="{'loading-initial': collection.loading && collection.models.length <= 0}"
        >
            <slot
                v-if="collection.models.length <= 0"
                name="no-items"
            >
                <div class="no-results">
                    {{ $t('inputs.modelSelect.noResults') }}
                </div>
            </slot>

            <ul v-else>
                <li
                    v-for="(model, index) in collection.models"
                    :key="model.identifier()"
                    class="item"
                    :class="{active: isSelected(model)}"
                    :tabindex="index"
                    @click="select(model, $event)"
                >
                    <slot
                        name="item"
                        :model="model"
                    >
                        {{ getLabel(model) }}
                    </slot>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import InputDebounced from '@/components/common/InputDebounced';
import InputLayout from '@/components/common/InputLayout';
import Collection from '@/models/vue-mc/Collection';
import {BFormInput} from 'bootstrap-vue';
import Model from '@/models/vue-mc/Model';
import {get} from 'lodash';

export default {
    name: 'DropdownCollection',
    components: {InputLayout, InputDebounced},
    props: {
        collection: {
            type: Collection,
            required: true,
        },
        value: {
            type: Model,
            required: false,
            default: undefined,
        },
        unselectedText: {
            type: String,
            required: false,
            default: undefined,
        },
        allowDeselect: {
            type: Boolean,
            required: false,
            default: false,
        },
        placeholder: {
            type: String,
            required: false,
            default: function() {
                return this.$t('inputs.modelSelect.placeholder');
            },
        },
        label: {
            type: [String, Function],
            required: false,
            default: () => ((model) => model.toString()),
        },
    },
    data: () => ({
        BFormInput: BFormInput,
    }),
    watch: {
        async 'collection.query'() {
            this.collection.clear();
            await this.collection.page(0).fetch();
        },
    },
    methods: {
        getLabel(model) {
            if (typeof this.label === 'function') {
                return this.label(model);
            }

            return get(model, this.label);
        },
        isSelected(model) {
            if (this.value === undefined) {
                return false;
            }

            return this.value.isSame(model);
        },
        async select(model, event) {
            if (this.allowDeselect && this.isSelected(model)) {
                this.$emit('input', undefined);
            } else {
                this.$emit('input', model);
            }
            event.target.blur();
        },
        async loadInitial() {
            if (!this.collection.loading && this.collection.models.length <= 0) {
                await this.collection.page(0).fetch();
            }
        },
        async loadMore() {
            if (!this.collection.loading) {
                await this.collection.fetch();
            }
        },
    },
};
</script>

<style lang="scss">
    .model-select {
        position: relative;
        .search {
            position: relative;
            display: flex;
            align-items: center;
            transition: background-color .15s ease-in-out;

            &,
            .form-control {
                cursor: pointer;
            }

            .selected-overlay {
                position: absolute;
                left: 0;
                padding: inherit;
                pointer-events: none;
                background-color: inherit;
            }

            .icon {
                pointer-events: none;

                .caret {
                    transition: transform .3s ease;
                    will-change: transform;
                }
            }

            &:hover,
            &:focus-within {
                background: darken($gray-100, 7.5%);
            }
        }

        .items {
            position: absolute;
            z-index: 2;
            display: none;
            width: 100%;
            max-height: 16rem;
            overflow: auto;
            background-color: $white;
            border-radius: 0 0 $border-radius $border-radius;
            box-shadow: 5px 5px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23);

            .no-results {
                padding: $dropdown-item-padding-y $dropdown-item-padding-x;
            }

            ul {
                width: 100%;
                padding: .5rem;
                margin-bottom: 0;
                list-style: none;
                border-radius: 0 0 $border-radius $border-radius;
            }

            .item {
                padding: $dropdown-item-padding-y $dropdown-item-padding-x;
                cursor: pointer;
                border-radius: $border-radius;
                outline: 0;

                &:not(:last-of-type) {
                    margin-bottom: .25rem;
                }

                &:hover {
                    color: color-yiq($gray-100);
                    background-color: $gray-100;
                }

                &.active {
                    color: color-yiq($primary);
                    background-color: $primary;
                }
            }
        }

        &:focus-within {
            .items:not(.loading-initial) {
                display: flex;
            }

            .search {
                &,
                .form-control {
                    cursor: text;
                }

                .selected-overlay {
                    display: none;
                }
                .caret {
                    transform: rotate(-180deg);
                }
            }
        }
    }
    [invalid-feedback] {
        .model-select .search .icon {
            color: $red;
        }
    }
</style>
