import Role from '@/library/enumerations/Role';
import {applyAttributesMixin} from '@/models/vue-mc/helpers';
import {omit} from 'lodash';
import Model from './vue-mc/Model';
import Collection from './vue-mc/Collection';

export class Invitation extends Model {
    /**
     * Default attributes that define the "empty" state.
     *
     * @return {object}
     */
    defaults() {
        return {
            id: null,
            emails: [],
            model: undefined,
            roles: [Role.CLIENT],
        };
    }

    getSaveData() {
        const data = Object.assign({}, super.getSaveData());

        if (data.model instanceof Model) {
            data.modelType = data.model.constructor.endpoint;
            data.modelId = data.model.identifier();
        }

        if (Array.isArray(data.roles)) {
            data.roles = data.roles.filter(role => role !== Role.CLIENT);
        }

        return omit(data, 'model');
    }

    /**
     * Options of this model.
     *
     * @return {object}
     */
    options() {
        return {
            endpoint: 'invitations',
        };
    }
}

export const Invitations = applyAttributesMixin(class Invitations extends Collection {
    /**
     * Options of this collection.
     *
     * @return {object}
     */
    options() {
        return {
            model: Invitation,
        };
    }
});
